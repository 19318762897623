.drop-down > * {
    z-index: 11111111111;
}
.drop-down {
    max-height: 400px;
    width: 200px;
    /* overflow: scroll; */
    margin: 0 20px;
    padding: 10px;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 11111111111;
    background: #fff;
    transform: translate(-50%, -50%);
}
.drop-down li {
    list-style-type: none;
    display: inline-block;
    width: 100%;
    padding: 0 10px
}
.drop-items {
    overflow: scroll;
    height: 350px;
    padding: 20px 0;
}