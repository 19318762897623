.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.file-uploader-container {
  height: 100px;
  width: 100px;
  background: #f2f2f2;
  border: 2px solid #ccc;
  border-radius: 5px;
  position: relative;
  margin: 0 10px
}
.img-container{
  position: relative;
}
    .img-container i{
      color: black;
      opacity: 0.8;
    }
.file-uploader-add-icon {
  position: absolute;
  top: -10px;
  right: 0px;
  font-size: 18px;
  z-index: 9999;
  cursor: pointer;
}
.file-uploader-container img {
  vertical-align: initial;
  object-fit: cover;
}
.multi-selector {
  height: 90px;
  padding: 5px 5px 5px 13px;
  text-align: left;
  border: 1px solid #e5e5e5;
  width: 100%;
  border-radius: 3px;
  display: inline-block;
  overflow: scroll;
}
.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 11111111;
}
.drop-down-input  {
    display: flex;
    justify-content: center;
    align-items: center;
}
.report-view {
  width: 400px;
  height: 35px;
  display: flex;
}
.report-name {
  border: 2px solid #ccc;
  width: 50%;
  font-weight: 400
}
.open-lab-report {
  width: 107px;
  height: 35px;
  cursor: pointer;
  /* background: blue; */
  background: #1c3667;
  color: #fff;
  margin: 0 20px;
  padding: 7px 16px;
  border-radius: 10px;
}
.no-scroll {
  overflow: hidden;
}