.add-cause{
    padding: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
}
.user_section.complaints .add-cause button{
    margin: 0;
    color: #1c3667;
    width: 40%;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.add-more{
    height: 100px;
    width: 100px;
    /* background: #f2f2f2; */
    border: 2px dashed #ccc;
    border-radius: 5px;
    position: relative;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    cursor: pointer;
}
.complaints-section .section_second.report-card{
    height: 250px;
}