.buttons {
    display: flex;
    align-items: center
}
.buttons i {
    color: grey !important;
    font-size: 18px!important;
    padding: 0 0.3rem!important;
    margin-left: auto!important;
    cursor: pointer;
}