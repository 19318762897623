#print-form,
#print-form *{
    visibility: hidden;
}
#print-form{font-size: 14px;padding: 10px 20px;text-align: left;font-size: 20px;}
.row{margin:0}
.header-logo{width:80%;float: left;}
.address-block{
text-align: right;
    .email-detail{
        display: block;
        color: #777;
    }
}

.form-details{
    padding: 30px 20px;
    h4{font-size: 32px;border-bottom: 1px solid #ccc;width:100%;padding-bottom:20px;span{float:right;font-weight: 400;}}
    .details-head{border-bottom: 1px solid #ccc; padding-bottom:10px 0;}
    span{text-transform: uppercase;}label{display: block}
    .form-label-values{
        margin: 30px 0;
        .col-md-4, .col-md-12{margin-top: 20px;}
    .comment-lines{border-bottom: 1px solid;margin-top: 50px;padding:0 15px;}
    }
    h5{font-weight: 400; font-size: 22px;text-transform: uppercase;margin: 0;padding: 0;}
}

@media print{
.no-print, .no-print *{ display: none;}
#print-form,
#print-form *{
    visibility: visible;
}
}

//
.hand{cursor: pointer;} 
.statusSelect{
    span{
        margin-right: 10px;
        i{
            margin-right: 5px;
        }
    }
}

.filter-form{
    padding: 10px 25px;
    h4{
        i{float: right;margin-right: 10px;}
    }
    form{
        .col-md-12{padding: 0;}
    }
    select{
        option{
            text-transform: capitalize;
        }
    }
    .button-grp{padding-left:15px;}
    button{
        width: 130px;
        height: 38px;
        margin: 0 25px 0 0px;
        font-size: 14px;
        border-radius: 10px;
        border: none;
        color: #fff;
        &.update{
            background: #1c3667;
        }
        &.cancel{
            background: #7d8ead;
        }
    }
}

button{
    &:disabled{
        opacity: 0.8;
        cursor: not-allowed;
    }
}
.display-none{
    display: none;
}
.user_section.complaints select.dock-audit-select{
    width: 80px;
}

.user_section.complaints{
    .checkboxes-wrapper{
        label{
            display: flex;
            align-items: center;
            user-select: none;
            input[type="checkbox"]{
                margin: 0;
                margin-right: 5px;
            }
        }        
    }
    .font-15{
        font-size: 15px;
    }
    .fd-column{
        display: flex;
        flex-direction: column;
    }
    .mb-1{
        margin-bottom: 1rem;
    }
    .mt-1{
        margin-top: 1rem;
    }
}

.d-flex {
    display: flex;
}

.justify-content-between {
    justify-content: space-between
}